@import 'mixins';

::selection{
    // background-color: $main-orange;
    // color: white;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

body{
    overflow-x: hidden;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}


.order-button{
    cursor: pointer;
}

.site-header{
    height: 125px;
    width: 100%;
    // background-color: red;
    @include flexColumn(flex-start);

    &__menu-cover{
        position: absolute;
        width: 100%;
        // height: calc(100vh - 125px);
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.281);
        z-index: 999;
        display: none;
    }

    &__mobile-button{
        display: none;
    }

    &__up-header{
        width: 100%;
        height: 50px;
        background-color: $main-subcolor;
        box-shadow: 0px 1px 0px 0px $main-light-gray;
        @include flexRow(center);
    }

    &__up-container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(flex-end);
    }

    &__nav{
        // background-color: plum;
        @include flexRow(center);
    }

    &__nav-menu{
        list-style: none;
        @include flexRow(flex-end);
    }

    &__nav-item{
        height: 100%;
        // background-color: orange;
        margin: 0px 20px;
        @include flexColumn(center);
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
        }
    }

    &__nav-link{
        text-decoration: none;
        font-size: 14px;
        color: $text;
        font-weight: 700;
        transition: 0.3s;
        &:hover{
            color: $red;
        }
    }

    &__down-header{
        width: 100%;
        height: 75px;
        // background-color: yellow;
        @include flexRow(center);
    }

    &__down-container{
        max-width: 1360px;
        width: 100%;
        height: 75px;
        // padding: 20px 0px;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__logo-block{
        width: 182px;
        height: 50px;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
        cursor: pointer;
    }
    
    
    &__logo{
        max-width: 100%;
        max-height: 100%;
    }

    &__content-block{
        max-width: 1000px;
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexRow(space-between);
        align-items: center;
    }

    &__phone-container{
        // width: 100%;
        // background-color: orange;
        @include flexRow(flex-end);
    }

    &__phone{
        background-color: #87a9c2;
        width: 320px;
        height: 50px;
        border-radius: 10px;
        color: $main-color;
        font-weight: 400;
        font-size: 30px;
        text-decoration: none;
        @include flexColumn(center);
        position: relative;
        text-align: center;
        color: white;
        transition: 0.3s;
        // border-bottom: 1px solid rgba(40,143,216,0.3);

        &:hover{
            color: $red;
            // border-color: $red;
        }
    }

    &__menu{
        // margin-top: 5px;
        // background-color: yellow;
        @include flexRow(space-between);
        align-items: center;
        list-style: none;
        height: 100%;
        z-index: 9999;
    }

    &__menu-item{
        text-decoration: none;
        // border-bottom: 1px solid rgba(40,143,216,0.3);
        color: $main-color;
        text-decoration: none;
        font-size: 16px;
        height: 100%;
        // background-color: red;
        @include flexColumn(center);
        font-weight: 500;
        cursor: pointer;
        position: relative;
        transition: 0.3s;
        margin: 0px 20px 0px 20px;
    }

    &__menu-item_hover{
        &:hover{
            .site-header__menu-item-name{
                color: $red;
            }
            .site-header__submenu{
                display: flex;
                // @include flexColumn(flex-start);
            }
            // border-color: $red;
        }
    }

    &__menu-item-name{
        text-decoration: none;
        color: $main-color;
    }

    &__submenu{
        position: absolute;
        width: 480px;
        // height: 480px;
        background-color: white;
        @include flexRow(space-between);
        flex-wrap: wrap;
        z-index: 1;
        top: 75px;
        display: none;
        // padding: 10px 0px 10px 0px;
        list-style: none;
        // box-shadow: 0px 5px 5px 0px black;
    }

    .submenu-close{
        display: none;
    }
    
    &__submenu-item{
        position: relative;
        width: 240px;
        // background-color: yellow;
        font-size: 14px;
        // background-color: red;
        // background-color: yellow;
    }

    &__submenu-item_hover{
        &:hover{
            background-color: darken(white, 5%);
            .site-header__submenu-link{
                color: $red;
            }
            .site-header__submenu-arrow{
                path{
                    fill: $red;
                }
            }

            .site-header__extra-menu{
                @include flexColumn(flex-start);
                top: 0px;
            }
        }
    }

    &__submenu-arrow{
        position: absolute;
        // background-color: aqua;
        right: 10px;
        display: none;
        path{
            transition: 0.3s;
        }
    }

    &__submenu-link{
        padding: 10px;
        width: calc(100% - 20px);
        min-height: 30px;
        transition: 0.3s;
        border: none;
        color: $main-color;
        cursor: pointer;
        text-decoration: none;
        // height: 100%;
        // background-color: pink;
        @include flexRow(flex-start);
        align-items: center;
        position: relative;
    }

    &__submenu-img-block{
        // background-color: orange;
        width: 25px;
        height: 25px;
        @include flexColumn(center);
        align-items: center;
    }
    
    &__submenu-img{
        max-width: 25px;
        max-height: 25px;
        // background-color: aqua;
    }
    
    &__submenu-name{
        width: 190px;
        margin-left: 15px;
        // min-height: 30px;
        // background-color: aqua;
    }

    &__extra-menu{
        // position: absolute;
        position: absolute;
        width: 240px;
        // height: 480px;
        // overflow-y: scroll;
        background-color: darken(white, 10%);
        z-index: 1;
        top: 0px;
        // bottom: 100%;
        left: 240px;
        display: none;
        // padding: 10px 0px 10px 0px;
        list-style: none;
        z-index: 999;

        &::-webkit-scrollbar{
            width: 2px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $red;
        }
    }

    &__extra-menu-header{
        width: calc(100% - 20px);
        padding: 5px 10px 5px 10px;
        cursor: default;
        color: $red;
        &:not(:first-child){
            margin-top: 10px;
        }

    }

    &__extra-menu-item{
        // @include flexRow(center);
    }

    &__extra-menu-link{
        padding: 0px 15px 0px 15px;
        width: calc(100% - 30px);
        min-height: 30px;
        transition: 0.3s;
        border: none;
        color: $main-color;
        cursor: pointer;
        text-decoration: none;
        // height: 100%;
        // background-color: red;
        @include flexRow(flex-start);
        align-items: center;
        position: relative;
        &:hover{
            color: $red;
        }
    }
    
    &__extra-menu-name{
        width: 190px;
        margin-left: 15px;
        // min-height: 30px;
        // background-color: aqua;
    }

    .to-submenu{
        display: none;
        padding: 0px !important;
        min-height: 0px !important;
    }
}

.header-brands-block{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 0px;
    z-index: 9999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__container{
        max-width: 800px;
        // padding: 20px;
        @include flexColumn(flex-start);
        width: 100%;
        height: 500px;
        // background-color: red;
        background-color: white;
        position: relative;
    }

    &__close-button{
        width: 40px;
        height: 40px;
        // background-color: orange;
        position: absolute;
        right: 0px;
        top: 0px;
        @include flexRow(center);
        align-items: center;
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__top-block{
        @include flexColumn(flex-start);
        padding: 20px;
        width: calc(100% - 40px);
        background-color: $main-subcolor;
        height: 80px;
    }

    &__header{
        font-size: 24px;
        font-weight: 500;
        height: 30px;
        // background-color: yellow;
    }

    &__search-bar{
        width: calc(100% - 20px);
        height: 40px;
        padding: 0px 10px 0px 10px;
        outline: none;
        margin-top: 10px;
    }

    &__brands-container{
        width: calc(100% - 40px);
        height: 380px;
        padding: 20px;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main-color;
        }
    }

    &__link{
        width: 130px;
        height: 50px;
        // background-color: pink;
        margin: 10px;
        @include flexRow(center);
        align-items: center;
    }
    
    &__img-block{
        @include flexRow(center);
        align-items: center;
        width: 115px;
        height: 30px;
    }

    &__img{
        width: 110px;
        height: 25px;
        object-fit: contain;
    }
}

@media (max-width: 1360px){
    .site-header{
        // background-color: red;
        // height: 300px;
        height: unset;

        &__up-header{
            order: 2;
            // background-color: yellow;
            height: unset;
            margin-top: 15px;
            padding: 10px 0px 10px 0px;
            @include flexColumn(flex-start);
            // display: none;
        }

        &__up-container{
            // background-color: pink;
            @include flexColumn(flex-start);
            align-items: center;
            // height: unset;
            position: relative;
            z-index: 9999;
        }

        &__nav{
            // margin-top: 10px;
            margin: 0px;
            top: 50px;
            width: 100%;
            @include flexColumn(flex-start);
            position: absolute;
            display: none;
            background-color: $main-subcolor;
            box-shadow: 0px 1px 0px 0px $main-light-gray;
            z-index: 9999;
            // background-color: plum;
        }
        
        &__nav_active{
            @include flexColumn(flex-start);
        }

        &__nav-menu{
            // background-color: orange;
            @include flexColumn(flex-start);
            width: 100%;
            align-items: center;
        }

        &__nav-item{
            margin: 0px;
            // background-color: aqua;
            width: 100%;
            text-align: center;
            padding: 5px 0px 5px 0px;
            &:not(:first-child){
                // margin-top: 10px;
            }
        }

        &__nav-link{
            font-size: 16px;
        }

        &__down-header{
            order: 1;
            height: unset;
            // background-color: yellow;
        }
        
        &__down-container{
            @include flexColumn(flex-start);
            height: unset;
            // flex-wrap: wrap;
            // background-color: pink;
        }

        &__content-block{
            margin-top: 20px;
            max-width: unset;
            width: 100%;
            height: unset;
            @include flexColumn(flex-start);
            // background-color: plum;
        }

        &__menu{
            height: unset;
            // background-color: orange;
            width: 100%;
            @include flexColumn(flex-start);
        }

        &__menu-item{
            font-size: 18px;
            text-align: center;
            // background-color: aqua;
            width: 100%;
            // pointer-events: none;
            &:not(:first-child){
                margin-top: 15px;
            }
        }
    
        &__menu-item-name{
            text-decoration: none;
            color: $main-color;
        }
    
        &__submenu{
            position: absolute;
            // width: 240px;
            width: 100vw;
            // left: 0px;
            // height: 480px;
            // background-color: red;
            flex-wrap: wrap;
            height: 100vh;
            z-index: 1;
            top: 0px;
            // padding: 10px 0px 10px 0px;
            list-style: none;
            @include flexColumn(flex-start);
            display: none;
            // box-shadow: 0px 5px 5px 0px black;
            overflow-y: scroll;
        }

        .submenu-close{
            @include flexRow(flex-start);
            // height: 30px;
            pointer-events: all;
        }
        
        &__submenu-item{
            position: relative;
            width: 100%;
            // background-color: yellow;
            font-size: 14px;
            // background-color: red;
            // background-color: yellow;
        }
    
        &__submenu-arrow{
            position: absolute;
            // background-color: aqua;
            right: 10px;
            display: none;
            path{
                transition: 0.3s;
            }
        }
    
        &__submenu-link{
            padding: 10px;
            width: calc(100% - 20px);
            min-height: 30px;
            transition: 0.3s;
            border: none;
            color: $main-color;
            cursor: pointer;
            text-decoration: none;
            // height: 100%;
            // background-color: pink;
            @include flexRow(flex-start);
            align-items: center;
            position: relative;
        }
    
        &__submenu-img-block{
            // background-color: orange;
            width: 25px;
            height: 25px;
            @include flexColumn(center);
            align-items: center;
        }
        
        &__submenu-img{
            max-width: 25px;
            max-height: 25px;
            // background-color: aqua;
        }
        
        &__submenu-name{
            width: calc(100% - 40px);
            margin-left: 15px;
            // min-height: 30px;
            // background-color: aqua;
        }
    
        &__extra-menu{
            // position: absolute;
            position: absolute;
            width: 100vw;
            height: 100vh;
            // height: 480px;
            // overflow-y: scroll;
            background-color: white;
            z-index: 1;
            top: 0px;
            // bottom: 100%;
            left: 0px;
            // background-color: red;
            // padding: 10px 0px 10px 0px;
            list-style: none;
            z-index: 999;
            @include flexColumn(flex-start);
            display: none;
            overflow-y: scroll;
    
            &::-webkit-scrollbar{
                width: 2px;
            }
    
            &::-webkit-scrollbar-thumb{
                background-color: $red;
            }
        }
    
        &__extra-menu-header{
            width: calc(100% - 20px);
            padding: 5px 10px 5px 10px;
            cursor: default;
            color: $red;
            &:not(:first-child){
                margin-top: 10px;
            }
    
        }
    
        &__extra-menu-link{
            padding: 0px;
            width: 100%;
            min-height: 30px;
            transition: 0.3s;
            border: none;
            color: $main-color;
            cursor: pointer;
            text-decoration: none;
            // height: 100%;
            // background-color: red;
            @include flexRow(center);
            align-items: center;
            text-align: center;
            position: relative;
            &:hover{
                color: $red;
            }
        }

        &__extra-menu-back{
            *{
                color: $red !important;
            }
        }
        
        &__extra-menu-name{
            width: calc(100% - 30px);
            margin-left: 0px;
            min-height: 30px;
            @include flexRow(center);
            align-items: center;
            // min-height: 30px;
            // background-color: aqua;
        }
        
        .to-submenu{
            display: flex;
            // padding: 0px 15px 0px 15px !important;
            // padding: 0px !important;
            min-height: 30px !important;
        }

        &__phone{
            font-size: 20px;
            width: unset;
            height: unset;
            padding: 10px 15px 10px 15px;
            margin-top: 15px;
        }

        &__mobile-button{
            width: 40px;
            // background-color: red;
            height: 40px;
            @include flexColumn(center);
            align-items: center;
            svg{
                path{
                    stroke: black;
                }
            }
        }

        &__mobile-button_active{
            // background-color: red;
            @include flexRow(center);
            align-items: center;
            path{
                &:nth-child(2){
                    opacity: 0;
                }
                
                &:nth-child(1){
                    transform: rotate(-45deg) translate(8px, 11px);
                    // transform: translate(10px);
                    transform-origin: 20px 20px;
                }
                &:nth-child(3){
                    // opacity: 0;
                    transform-origin: 20px 20px;
                    transform: rotate(45deg) translate(-5px, -6px);
                    // transform: translate(10px);
                }
            }
        }
    }
}